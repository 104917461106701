import { Modal } from "bootstrap";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const successToast = (message) => {
    window.store.dispatch('showSuccessModal', message);
};

export const errorToast = (message) => {
    window.store.dispatch('showErrorModal', message);
};

export const helper = {
    showLogin() {
        let login = new Modal('#staticBackdrop-one');
        login.show();
    },
    openModal(id) {
        setTimeout(() => {
            let modal = new Modal(id);
            modal.show();
        }, 100)
    },
    closeModal(id) {
        setTimeout(() => {
            let closeModal = document.getElementById(id)
            let modal = Modal.getInstance(closeModal);
            if(modal) {
                modal.hide();
            }
        }, 100)
    },
    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    },
    formatNumber(value){
        if (value !== null) {
            return parseFloat(value).toFixed(2);
        } else {
            return 0;
        }
    },
    downloadExcel(data, fileName){
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, fileName);
    },
    downloadPdf(data, fileName) {
        const doc = new jsPDF();
        doc.autoTable({
            head: [Object.keys(data[0])],
            body: data.map((obj) => Object.values(obj)),
        });
        doc.save(fileName);
    },
    notNull(item){
        return item !== ('' || null || undefined)
    },
    compareLength: (len, prop) => {
        return prop.length == len
    },
    testRegex(regex, data) {
        return regex.test(data);
    },
    //----- sanitization helpers
    sanitizeDetails(field, prop, sliceLength) {
        let regexMap = {
            userId : /[^a-zA-Z0-9]/g,
            password : /[^a-zA-Z0-9]/g,
            login : /\s+/g,
            oldLoginPassword: /[\s]/g,
            referCode : /[^0-9]/g,
            withdrawPassword: /[\s]/g
        }
        return prop.replace(regexMap[field] , '').slice(0, sliceLength);
    },
    sanitizeNumber(prop, maxValue) {
        if (prop != '') {
            let stringValue = prop.toString();
            stringValue = stringValue.replace(/[^0-9]/g, '').replace(/[+-]/g, '');
            let sanitizedNumber= '';
            maxValue == 999999 ? sanitizedNumber = stringValue.slice(0,6) ?? '' : sanitizedNumber = stringValue == '' ? '' : Math.max(0, Math.min(maxValue, stringValue)).toString()
            return sanitizedNumber
        }
        return prop;
    },
    getPaginationData(data) {
        return {
            currPage: data.current_page,
            from: data.from,
            to: data.to,
            lastPage: data.last_page,
            perPage: data.per_page,
            total: data.total,
        }
    },
    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    },
    formatNumber(value) {
        if (value !== null) {
            return parseFloat(value).toFixed(2);
        } else {
            return 0;
        }
    },
  
    
    //----- sanitization helpers
}