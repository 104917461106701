const state = {
    siteSettings: null,
    gamesData: null,
    captchaData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    languagesData:null,
    siteVersion:null,
    underMaintenance:null,
    sports_list:null,
    successMsg: null,
    errorMsg: null,
    globalLoader: false,
    favouriteGames:null,
};
const getters = {
    siteSettings: state => state?.siteSettings,
    languagesData: state => state?.languagesData,
    gamesData: state => state?.gamesData,
    captchaData: state => state?.captchaData,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    clickBetActive: state => state?.clickBetValue?.find(item => item.isSelected) ?? false,
    siteVersion: state => state?.siteVersion,
    underMaintenance: state => state?.underMaintenance,
    sports_list: state => state?.sports_list,
    successMsg: state => state?.successMsg,
    errorMsg: state => state?.errorMsg,
    globalLoader: state => state?.globalLoader,
    favouriteGames:state => state?.favouriteGames,
};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setSiteVersion({ commit }, siteVersion) {
        commit('setSiteVersion', siteVersion);
    },
    setUnderMaintenance({ commit }, underMaintenance) {
        commit('setUnderMaintenance', underMaintenance);
    },
    setSportsList({ commit }, sports_list) {
        commit('setSportsList', sports_list);
    },
    showSuccessModal({ commit }, message) {
        commit('showSuccessModal', message);
        setTimeout(() => {
            commit('showSuccessModal', null);
        }, 2000);
    },
    showErrorModal({ commit }, message) {
        commit('showErrorModal', message);
        setTimeout(() => {
            commit('showErrorModal', null);
        }, 2000);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setSiteVersion(state, siteVersion) {
        state.siteVersion = siteVersion;
    },
    setUnderMaintenance(state, underMaintenance) {
        state.underMaintenance = underMaintenance;
    },
    setSportsList(state, sports_list) {
        state.sports_list = sports_list;
    },
    showSuccessModal(state, message) {
        state.successMsg = message
    },
    showErrorModal(state, message) {
        state.errorMsg = message
    },
    setGlobalLoader(state, globalLoader) {
        // Manage body scroll when loader is on
        if(globalLoader){
            document.body.classList.add('no-scroll');
        }else{
            document.body.classList.remove('no-scroll');
        }
        state.globalLoader = globalLoader;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};