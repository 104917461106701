<template>
	<template v-if="!underMaintenance">
		<Header v-if="checkForHeaderShow()"></Header>
	</template>
	<div data-bs-toggle="modal" data-bs-target="#notification-alert" ref="notifyMe">
	</div>
	<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications"/>
   	<NavBar></NavBar>

   <div v-if="onLine">
      <router-view  @updateAuthToken="refreshAuthToken"/>
   </div>

   	<MobileFooter v-if="!underMaintenance && $route.name != 'NotFound'"></MobileFooter>

	<div :class="{ show: successMsg }" class="successfully-wrapper" style="z-index: 9999999999999999;" ref="successmodal" id="successfullyModal">
		<SuccessModal :message="successMsg"></SuccessModal>
	</div>

	<div :class="{ show: errorMsg }" style="z-index: 9999999999999999;" class="successfully-wrapper" id="wrongtoaster">
		<ErrorModal :message="errorMsg"></ErrorModal>
	</div>
   	<ChatComponent></ChatComponent>
	<Offer />
	<div id="loading" class="loading-wrap loader" v-if="globalLoader">
        <ul class="loading">
            <li><img  rel="preload"   src="@/assets/images/loading.gif"></li>
            <li>Loading...</li>
        </ul>
    </div>
	<div class="back-to-top-page-scroll" :class="{ 'd-none': scY < 300 }" v-if="$route.name == 'Home'"
		@click="goToTop()">
		<button id="myBtn" title="Go to top">
			<img src="@/assets/images/arrow_up.png" alt="" loading="lazy" />
		</button>
	</div>
</template>
<script>
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import { setHeaders } from '@/shared/services/headers';
import axios from 'axios';
import { mapGetters } from 'vuex';
import socketService from "./socket/sport-socket-service";
import { defineAsyncComponent } from 'vue';

export default {
   name: 'App',
   components: {
        Header: defineAsyncComponent(() =>
            import('@/shared/components/header/Header.vue')
        ),
        NavBar: defineAsyncComponent(() =>
            import('@/shared/components/header/NavBar.vue')
        ),
        MobileFooter: defineAsyncComponent(() =>
            import('@/shared/components/footer/MobileFooter.vue')
        ),
        SuccessModal: defineAsyncComponent(() =>
            import('@/shared/components/modal/SuccessModal.vue')
        ),
		ErrorModal: defineAsyncComponent(() =>
            import('@/shared/components/modal/ErrorModal.vue')
        ),
		NotificationAlert: defineAsyncComponent(() =>
            import('./shared/components/modal/NotificationAlert.vue')
        ),
		ChatComponent: defineAsyncComponent(() =>
            import('./modules/chat/views/ChatComponent.vue')
        ),
		Offer: defineAsyncComponent(() =>
            import('./shared/components/header/Offers.vue')
        ),

    },
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			scY: 0,
			scTimer: 0,
		}
	},
	computed: {
		...mapGetters(['successMsg', 'errorMsg', 'siteVersion','underMaintenance','eventDetailHideSections','globalLoader']),
		routePath() {
			return this.$route.name;
		},
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	},
	created() {
		if (this.$siteSettings?.data?.front_css != null && this.$siteSettings?.data?.front_css != '') {// Create a style element
			const styleElement = document.createElement('style');
			styleElement.type = 'text/css';
			styleElement.id = 'custom-style'
			styleElement.appendChild(document.createTextNode(this.$siteSettings?.data?.front_css));
			document.head.insertAdjacentElement('beforeend', styleElement);
		}
	},
  	async mounted() {
		window.addEventListener('load', () => {
			setTimeout(() => {
				window.scrollTo({ top: 0 });
			}, 500);
		});
		//for use on js pages
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		
		//for add on all APIs
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();
		await this.getSiteVersion();
		setTimeout(()=>{
            if(this.$auth?.status){
                // callFavouriteApis();
                if(!this.$auth.user.notificationSubscribed && !this.$auth.user.notificationUnsubscribed) {
                    this.requestNotificationPermission()
                }
            }
        },1000)	
		//checking internet connection
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		window.addEventListener('scroll', this.onScroll);
		this.socketAllEvents();
		this.$errorToast(null);
		this.$successToast(null);
   	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},
   	methods: {
		requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}

			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			this.$refs.notifyMe.click()
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		goToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		onScroll() {
			if (this.scTimer) return;
			this.scTimer = setTimeout(() => {
				this.scY = window.scrollY;
				clearTimeout(this.scTimer);
				this.scTimer = 0;
			}, 100);
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						console.log(e);
					});

					
				
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
				this.$apiCall('post', 'SUBSCRIBE_NOTIFICATION', data).then(response => {
					if (response) {
						console.log(response)
					}
				}).catch(error => {
					if (error)
					this.$errorToast(error[0]);
				});
		},
		async getSiteVersion() {
			this.$apiCall('get', 'GET_SITE_VERSION', null, 'user', { domain: window.location.hostname }).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {
						let resData = response?.data?.data;
						let currentVersion = resData.version;
						let userSystemVersion = this.siteVersion || 0;		
						if (!userSystemVersion || currentVersion != userSystemVersion || !this.$siteSettings.data) {
							this.$store.dispatch('setSiteVersion', currentVersion);
							this.getSiteSettingsServiceCall();
						} else {
							this.setSiteSttings(this.$siteSettings.data);
						}
						if (resData.maintenance) {
							const { from_date, to_date } = resData.maintenance;
							const fromDateTime = new Date(from_date).getTime();
							const toDateTime = new Date(to_date).getTime();
							const currentDateTime = Date.now();

							const isInMaintenanceWindow = fromDateTime <= currentDateTime && currentDateTime <= toDateTime;

							if (fromDateTime && toDateTime && isInMaintenanceWindow) {
								this.$store.dispatch('setUnderMaintenance', resData.maintenance);
								this.$router.push({ name: 'Maintenance' });
							}
						}
						else {
							if(this.underMaintenance) {
								this.$router.push({name:'Home'});
							}
							this.$store.dispatch('setUnderMaintenance', null); 
						}
						this.$store.dispatch('setGlobalLoader', false);
					}
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},

		getSiteSettingsServiceCall() {
			this.$apiCall('get', 'GET_SITE_SETTINGS', null, 'user', { domain: window.location.hostname }).then(response => {
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {
						let resData = response?.data.data;
						this.$store.dispatch('setSettings', resData.site_settings);
						this.$store.dispatch('setGamesData', resData.casino);
						this.setSiteSttings(resData.site_settings);
					}
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},

		setSiteSttings(siteData) {
			try {
			if (siteData.language_translate_list) {
				this.$setCurrentLangData(siteData.language_translate_list, siteData.language_code, siteData.aws_url)
				this.$store.dispatch('setLanguagesData', siteData.language_translate_list);
			}

			this.$store.dispatch('setSettings', siteData);
			this.setManifest(siteData);

			// Create FAVICON
			const favElement = document.createElement('link');
			favElement.rel = 'icon';
			favElement.href = siteData.aws_url + siteData.storage_path.domain_image + siteData.fav_logo;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			const title = document.getElementById("title");
			title.text = siteData.domain_name;
			try {
				if (siteData.front_css != null && siteData.front_css != '' && !document.getElementById('custom-style')) {
					const styleElement = document.createElement('style');
					styleElement.type = 'text/css';
					styleElement.id = 'custom-style'
					styleElement.appendChild(document.createTextNode(siteData.front_css));
					document.head.insertAdjacentElement('beforeend', styleElement);
				}
			}
			catch (error) {
				this.$errorToast(error);
			}
			let is_offer = localStorage.getItem("is_offer");
            if (this.$auth?.status && this.$siteSettings?.data.offer != null && is_offer == null && this.$siteSettings?.data?.business_type == 2 && this.$auth.user.last_login_at) {
                this.$helper.openModal('#offer_pop_up');
            }
			if (this.$auth.status){
				this.getWalletBalance();
				this.getFavourites();
			}
		} catch (error) {
				this.$errorToast(error);
			}
		},
		getFavourites() {
			let data = {
				"game_type": 1
			}
			this.$apiCall('post', 'GET_FAVOURITE_LIST', data).then(response => {
				if (response) {
					if (response.status == 200) {
						if (response?.data?.status != 0) {
							var allData = response.data.data;
							var ids = [];
							for (var i = 0; i < allData.length; i++) {
								ids.push(allData[i].match_id)
							}
							this.$store.dispatch('setFavouriteGames', ids)
						}
					}
				}
			}).catch(error => {
				if (error) {
				}
			});
		},

		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		getWalletBalance() {
			this.$apiCall('post', 'WALLET_BALANCE').then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response?.data?.bl;
					user.expl = response?.data?.ex;
					user.bets_count = response?.data?.bc;
					this.$store.dispatch('setUser', user);
					if ((user.exp - this.$dateTime.currentTimestamp()) < 10) {
						this.refreshAuthToken();
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					console.log(error);
					this.$errorToast(error[0]);
				}
			});
		},
		refreshAuthToken(refresh = false) {
			this.$apiCall('post', 'AUTH_REFRESH').then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				if (refresh) {
					router.go();
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
				this.refreshInit = false;
			});
		},
		socketAllEvents(){
			if(this.$store.getters.stateUser){
				socketService.emit("sub",'depositRequest-'+this.$store.getters.stateUser.userid);					
				socketService.emit("sub",'logout-'+this.$store.getters.stateUser.userid);					
				socketService.emit("sub",'banned-'+this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type =="myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.$successToast('New Notification...');
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.$successToast('New Notification...')
						// }
					}
				});	
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					}else if(data.type == 'league_ban'){
						// league ban condition
					}
				});
			}			
		},
		checkForHeaderShow() {
			if ((this.eventDetailHideSections && this.eventDetailHideSections.includes('show-full-screen') && this.$route.name == 'sports-event-detail') || this.$route.name == 'NotFound') {
				return false;
			} else {
				return true;
			}
		},
   	}
}
</script>

