const state = {
    depositAmount: 0,
    walletHistory: null,
};
const getters = {
    depositAmount:state => state?.depositAmount,
    walletHistory: state => state?.walletHistory,
};
const actions = {
    setDepositAmount({ commit }, depositAmount) {
        commit('setDepositAmount', depositAmount);
    },
    setWalletHistoryData({ commit }, walletHistory) {
        commit('setWalletHistoryData', walletHistory);
    },
};
const mutations = {
    setDepositAmount(state, depositAmount){
        state.depositAmount =  depositAmount
    },
    setWalletHistoryData(state, walletHistory){
        state.walletHistory =  walletHistory
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};